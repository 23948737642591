import React from 'react';

const SwitchToAI = (props) => {
	return (
		<div>
			<svg
				width='25'
				height='25'
				viewBox='0 0 25 25'
				fill={props.data.fill}
				xmlns='http://www.w3.org/2000/svg'
				{...props.data}
			>
				<g clip-path='url(#clip0_1126_1074)'>
					<path
						d='M19.3287 8.46397L20.8908 7.87283L21.5168 6.30792C21.6045 6.08877 21.8187 6 21.9969 6C22.1751 6 22.3891 6.08873 22.4772 6.30744L23.1056 7.8775L24.6751 8.50567C24.8718 8.58435 25 8.77476 25 8.98566C25 9.19713 24.8708 9.38698 24.6755 9.46549L23.1056 10.0938L22.4774 11.6634C22.3987 11.8601 22.2083 11.9883 21.9974 11.9883C21.7859 11.9883 21.5961 11.859 21.5176 11.6638L20.8872 10.0881L19.3106 9.42371C19.1175 9.34154 18.9901 9.14968 18.9942 8.93738C18.9984 8.72657 19.1306 8.53864 19.3287 8.46397Z'
						fill={props.data.fill}
					/>
					<path
						fill-rule='evenodd'
						clip-rule='evenodd'
						d='M9.67525 0C8.22345 0 7.00263 0.917704 6.57123 2.32654L0.0486311 23.6045C-0.12529 24.1669 0.181544 24.7716 0.741502 24.9496C1.30125 25.1304 1.89482 24.8085 2.069 24.2437L3.9909 17.9718H15.3579L17.2798 24.2438C17.4207 24.7026 17.8367 24.9995 18.29 24.9995C18.3927 24.9995 18.5013 24.9847 18.6075 24.9495C19.1672 24.7715 19.4738 24.1665 19.3001 23.6042L12.7776 2.3266C12.3462 0.917923 11.129 0 9.67525 0ZM8.58994 2.96724C8.77447 2.36521 9.26563 2.15239 9.67352 2.15239C10.0819 2.15239 10.5727 2.36384 10.7571 2.96549L14.6978 15.8212H4.64925L8.58994 2.96724ZM21.9728 13.9219C21.3849 13.9219 20.9143 14.4072 20.9143 14.9973V23.9225C20.9143 24.5125 21.3849 24.9978 21.9728 24.9978C22.5608 24.9978 23.0313 24.5125 23.0313 23.9225V14.9973C23.0313 14.4072 22.5608 13.9219 21.9728 13.9219Z'
						fill={props.data.fill}
					/>
				</g>
				<defs>
					<clipPath id='clip0_1126_1074'>
						<rect width='25' height='25' fill={props.data.fill} />
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default SwitchToAI;
