import { useEffect, useState } from 'react';
import ReactDateTimePicker from '../DateTimePicker/ReactDateTimePicker';
import headerService from '../../services/header.service';
import useLocalStorage from '../../utils/localStorage';
import FilterModal from '../FilterButtonModal/FilterModal';
import { useTheme } from '@mui/styles';
import {
	InsightsBreadCrumbs,
	InsightsData,
} from '../../containers/Reports/Dashboard/InsightsData';
import { Grid, Typography } from '@mui/material';
import BreadCrumbs from '../Breadcrumbs/Breadcrumbs';
import InsightsButton from '../Insights/InsightsButton';
import BookMarkIcon from '../Bookmark/BookmarkButton';
import { customDashboardBreadcum } from './CommonHeaderData';
import ShareIcon from '@mui/icons-material/Share';
import ShareReports from '../../containers/ShareReports/ShareReports';
import { useSelector } from 'react-redux';

import moment from 'moment';
import SwitchToDiggML from '../../containers/SwitchToDigg/SwitchToDiggML';

const CommonHeader = () => {
	// -----------------------hooks----------------------------------
	let [dateRange, setDateRange]: any = useState(InsightsData.getDefaultRange());
	const [showPercentageChange, setShowPercentageChange]: any = useState(
		useLocalStorage.getItem('date_comparison')
	);
	const [selectedFilters, setSelectedFilters]: any = useState(
		useLocalStorage.getItem('filters')?.insights ||
			InsightsData.getDefaultCMOFilters()
	);
	// --------------------------------------------------------------
	const [open, setOpen] = useState(false);
	// --------------------------Service---------------------------------
	const theme: any = useTheme();

	// -------------------------------------------------------------------

	// ------------------------- Redux Management ---------------------

	const cmoDashboardStore: any = useSelector((state) => state);
	// ----------------------------------------------------------------

	const onChangeDateFilter = (dateRange: any) => {
		const data = {
			type: 'dateRange',
			data: dateRange,
		};
		headerService.emit(data);
		setDateRange(dateRange);
	};
	const onCompareChange = (event: any) => {
		const data = {
			type: 'onCompareChange',
			data: useLocalStorage.getItem('date_comparison'),
		};
		headerService.emit(data);
		setShowPercentageChange(useLocalStorage.getItem('date_comparison'));
	};

	useEffect(() => {
		const dates: any = useLocalStorage.getItem('dateRange');
		if (dates) {
			dateRange = dates;
			setDateRange({ ...dateRange });
		}
	}, []);

	const onResetFilter = () => ({
		date_type: '',
		attribution_model: 1,
		conditions: [],
		segment_id: null,
		start_date: InsightsData.getDefaultFilters().start_date,
		end_date: InsightsData.getDefaultFilters().end_date,
	});

	const onSubmitFilter = (selectedFilters: any) => {
		const updatedConditions = selectedFilters?.conditions.map(
			(condition: any) => {
				if (condition?.relational_operator === 'AND') {
					return { ...condition, relational_operator: 1 };
				} else if (condition?.relational_operator === 'OR') {
					return { ...condition, relational_operator: 2 };
				}
				return condition;
			}
		);

		const updatedFilters = { condition: updatedConditions };
		const data = {
			type: 'submitFilter',
			data: { ...selectedFilters, conditions: updatedFilters?.condition },
		};
		headerService.emit(data);
		setSelectedFilters(selectedFilters);
	};

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				padding: '10px',
				alignItems: 'center',
			}}
			className='helo'
		>
			<div style={{ width: '35em' }}>
				<Typography
					variant='h5'
					component='span'
					color={theme.palette.text.primary}
				>
					{location.pathname == '/custom/dashboard' ? (
						<span style={{ fontSize: '20px' }}>Custom Dashboard</span>
					) : (
						'CMO Dashboard'
					)}
				</Typography>
				<BreadCrumbs
					breadCrumbsList={
						location.pathname == '/custom/dashboard'
							? null
							: InsightsBreadCrumbs
					}
				/>
			</div>
			<div
				style={{
					float: 'right',
					display: 'flex',
					alignSelf: 'center',
					justifyContent: 'right',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<SwitchToDiggML
					left={location.pathname == '/custom/dashboard' ? '0rem' : ''}
				/>
				<Grid
					// item
					display='flex'
					justifyContent='start'
					style={{
						left: location.pathname == '/custom/dashboard' ? '0' : '2.5rem',
						position: 'relative',
					}}
				>
					<ShareReports
						name={'CMO'}
						id={1}
						filters={selectedFilters}
						shareReportsRequest={{
							...cmoDashboardStore?.cmo_request_body?.filter_data,
							start_date: moment(dateRange.start_date).format('YYYY-MM-DD'),
							end_date: moment(dateRange.end_date).format('YYYY-MM-DD'),
							dimension: cmoDashboardStore?.cmo_dimension_value,
							matrices: cmoDashboardStore?.cmo_metric_value,
						}}
						downloadPDFClassName={'dashboard'}
					/>
				</Grid>
				<Grid
					// item
					display='flex'
					justifyContent='start'
					style={{
						left: location.pathname == '/custom/dashboard' ? '0' : '1.5rem',
						position: 'relative',
					}}
				>
					<InsightsButton />
				</Grid>
				{location.pathname != '/custom/dashboard' ? (
					<BookMarkIcon
						payload={cmoDashboardStore?.cmo_bookmark_request_body || []}
						bookmarkName='CMO Dashboard Report'
					/>
				) : null}
				{location.pathname == '/insights/cmo/dashboard' ||
				location.pathname == '/custom/dashboard' ? (
					<FilterModal
						filters={InsightsData.getFiltersforCMO()}
						onReset={() => onResetFilter()}
						selectedFilters={selectedFilters}
						showSegments
						onSubmit={onSubmitFilter}
					/>
				) : null}
				{location.pathname == '/insights/cmo/dashboard' ||
				location.pathname == '/custom/dashboard' ? (
					<ReactDateTimePicker
						onChange={onChangeDateFilter}
						onCompareChange={(evt: any) => onCompareChange(evt)}
						defaultMonths={1}
						start_date={dateRange?.start_date}
						end_date={dateRange?.end_date}
						pre_start_date={dateRange?.pre_start_date}
						pre_end_date={dateRange?.pre_end_date}
						showComparison={true}
						showPercentageChange={showPercentageChange}
						minLastYear={2}
						isMultipleRanges
					/>
				) : null}
			</div>
		</div>
	);
};

export default CommonHeader;
