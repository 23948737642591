import {
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Dropdown from '../../components/Dropdown/Dropdown';
import Button from '../../components/Button/Button';
import { drawDOM, exportPDF } from '@progress/kendo-drawing';
import useLocalStorage from '../../utils/localStorage';
import useApiService from '../../services/api.service';
import ShareReportsEndpoints from './ShareReportsEndpoints';
import Input from '../../components/Input/Input';
import { useTheme } from '@mui/styles';

function ShareThroughLink({
	toaster,
	name,
	closePopup,
	loader,
	downloadPDFClassName,
}: any) {
	const { handleSubmit }: any = useForm();
	const theme: any = useTheme();
	const APIService: any = useApiService();
	const userData: any = useLocalStorage.getItem('userData');
	const [values, setValues]: any = React.useState(0);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValues((event.target as HTMLInputElement).value);
		setSelectedAccount('');
	};
	const [selectedAccount, setSelectedAccount]: any = useState();
	const [childAccounts, setChildAccounts] = useState([]);
	useEffect(() => {
		getChildAccounts();
	}, []);

	const getChildAccounts = async () => {
		const reqBody: any = {
			headers: { 'access-token': userData['access-token'] },
			request: {},
		};
		APIService.get(ShareReportsEndpoints.getChildAccounts(reqBody))
			.then((res: any) => {
				setChildAccounts(res?.data);
			})
			.catch((err: any) => {})
			.finally(() => {});
	};

	const shareableLink = async () => {
		if (!selectedAccount?.trim()) {
			toaster.addToast({
				type: 'error',
				message: 'Please fill email',
				timeout: 3000,
			});
		} else {
			let gridElement: any = document.getElementsByClassName(
				`${downloadPDFClassName}`
			)[0];
			loader.showLoader();
			drawDOM(gridElement, {
				paperSize: 'A4',
				scale: 0.3,
				forcePageBreak: '.page-break',
				keepTogether: '.keep-together',
			})
				.then((group) => {
					return exportPDF(group);
				})
				.then((dataUri) => {
					const reqBody: any = {
						headers: { 'access-token': userData['access-token'] },
						request: {
							// base64_string: dataUri.split(';base64,')[1],
							report_name:
								name == 'Channel Performance' ? 'channel_performance' : name,
							email: [selectedAccount],
							linking_type: 1,
							format_type: Number(values),
							base64_string: dataUri.split(';base64,')[1],
						},
					};

					APIService.post(ShareReportsEndpoints.uploadPDFtoServer(reqBody))
						.then((res: any) => {
							toaster.addToast({
								message: 'Email sent Successfully',
								type: 'success',
								timeout: 3000,
							});
						})
						.catch((err: any) => {
							toaster.addToast({
								type: 'error',
								message: err.message,
								timeout: 3000,
							});
						})
						.finally(() => {
							loader.hideLoader();
						});
				});
		}
	};

	const regName =
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	const submitLink = (data: any) => {
		if (!regName.test(selectedAccount)) {
			toaster.addToast({
				type: 'error',
				timeout: 3000,
				message: 'Please enter a valid email.',
			});
		} else shareableLink();
	};
	return (
		<Grid xs={12} container className='shareByLink'>
			<form onSubmit={handleSubmit(submitLink)} style={{ width: '100%' }}>
				<Grid xs={12} className='shareByEmail__radio'>
					<RadioGroup
						aria-labelledby='demo-controlled-radio-buttons-group'
						name='controlled-radio-buttons-group'
						value={values}
						style={{ display: 'flex', flexDirection: 'row', width: '60%' }}
						onChange={handleChange}
					>
						<Grid xs={6}>
							{' '}
							<FormControlLabel
								value='0'
								control={
									<Radio
										size='small'
										sx={{
											'& .MuiSvgIcon-root': {
												fontSize: 28,
											},
										}}
									/>
								}
								label='Shareable Link'
								labelPlacement='start'
							/>
						</Grid>
						<Grid xs={6}>
							<FormControlLabel
								value='1'
								control={
									<Radio
										size='small'
										sx={{
											'& .MuiSvgIcon-root': {
												fontSize: 28,
											},
										}}
									/>
								}
								label='Private Link'
								labelPlacement='start'
							/>
						</Grid>
					</RadioGroup>
				</Grid>

				{values == 1 ? (
					<Grid xs={12} container display={'flex'} marginTop={'1rem'}>
						<Grid xs={12}>
							<Typography marginBottom={'10px'}>Select Email</Typography>
						</Grid>
						<Grid xs={12}>
							<Dropdown
								valueSelector='value'
								itemList={childAccounts}
								label='name'
								name='email'
								//    defaultValue='createdAt'
								onChange={(e: any) => {
									setSelectedAccount(e.target.value);
								}}
								labelName='Select Email'
								selectedValue={selectedAccount}
							/>
						</Grid>
						<Grid xs={12}>
							<Typography
								style={{ fontSize: '12px', color: theme.palette.info.main }}
							>
								Link will be sent and accessed only by the above selected email
								id.
							</Typography>
						</Grid>
					</Grid>
				) : (
					<Grid xs={12} container display={'flex'} marginTop={'1rem'}>
						<Grid xs={12}>
							<Typography marginBottom={'10px'}>Enter Email</Typography>
						</Grid>
						<Grid xs={12}>
							<Input
								name='email'
								onChange={(e: any) => {
									setSelectedAccount(e.target.value);
								}}
							/>
						</Grid>
						<Grid xs={12}>
							<Typography
								style={{ fontSize: '12px', color: theme.palette.info.main }}
							>
								Link will be sent to the above entered email id and anyone can
								access it.
							</Typography>
						</Grid>
					</Grid>
				)}
				<Grid xs={12} textAlign={'center'}>
					<Button type='submit' btnText='Send Link' style={{ width: '30%' }} />
					<Button
						variant='outlined'
						type='button'
						btnText='Cancel'
						style={{ width: '30%' }}
						onClick={() => {
							closePopup();
						}}
					/>
				</Grid>
			</form>
		</Grid>
	);
}

export default ShareThroughLink;
