/* eslint-disable no-lone-blocks */
export const materixArray: { name: string; value: any }[] = [
	{
		name: 'Revenue',
		value: 6,
	},
	{
		name: 'Total Closed Won',
		value: 5,
	},
	{
		name: 'Current Pipeline',
		value: 4,
	},
	{
		name: 'Total Pipeline',
		value: 3,
	},
	{
		name: 'Spend',
		value: 1,
	},
	{
		name: 'Lead',
		value: 2,
	},
];

export const materixArrayName = () =>
	materixArray.map((item: any) => item.name);

export const dimensions: { name: string; value: any }[] = [
	{
		name: 'Channel',
		value: 1,
	},
	{
		name: 'Source',
		value: 2,
	},
	{
		name: 'Campaign',
		value: 3,
	},
	{
		name: 'Ad Group',
		value: 4,
	},
	{
		name: 'Keyword',
		value: 6,
	},
];

export const dimensionsForCWAndRevenue: { name: string; value: string }[] = [
	{
		name: 'Channel',
		value: 'channel',
	},
	{
		name: 'Source',
		value: 'source',
	},
	{
		name: 'Keyword',
		value: 'keyword',
	},
];

export const assignMetric = (index: number, type?: string): string => {
	if (type) {
		switch (index) {
			case 0:
				return 'Revenue';
			case 1:
				return 'Opportunity';
			case 2:
				return 'Spend';
			case 3:
				return 'Lead';
			default:
				return '';
		}
	} else
		switch (index) {
			case 0:
				return 'Revenue';
			case 1:
				return 'Total Closed Won';
			case 2:
				return 'Current Pipeline';
			case 3:
				return 'Total Pipeline';
			case 4:
				return 'Spend';
			case 5:
				return 'Lead';
			default:
				return '';
		}
};

export function monthDiff(dateFrom: any, dateTo: any) {
	return (
		dateTo.getMonth() -
		dateFrom.getMonth() +
		12 * (dateTo.getFullYear() - dateFrom.getFullYear())
	);
}

export function intersection(a: number[], b: number[]) {
	const setA = new Set(a);
	return b.filter((value: number) => setA.has(value));
}

export const hello = 'world';

export const revenueRawReport = {
	revenueToChannel: {
		series: [],
		seriesLabels: [],
	},
	revenueToSource: {
		series: [],
		seriesLabels: [],
	},
	revenueToKeyword: {
		series: [],
		seriesLabels: [],
	},
	revenueToCampaign: {
		series: [],
		seriesLabels: [],
	},
	revenueToAdGroup: {
		series: [],
		seriesLabels: [],
	},
};

export const totalClosedOneRawReport = {
	totalclosedWonToChannel: {
		series: [],
		seriesLabels: [],
	},
	totalclosedWonToSource: {
		series: [],
		seriesLabels: [],
	},
	totalclosedWonToKeyword: {
		series: [],
		seriesLabels: [],
	},
	totalclosedWonToCampaign: {
		series: [],
		seriesLabels: [],
	},
	totalclosedWonToAdGroup: {
		series: [],
		seriesLabels: [],
	},
};

export const currentPipelineRawReport = {
	currentPipelineToChannel: {
		series: [],
		seriesLabels: [],
	},
	currentPipelineToSource: {
		series: [],
		seriesLabels: [],
	},

	currentPipelineToKeyword: {
		series: [],
		seriesLabels: [],
	},
	currentPipelineToCampaign: {
		series: [],
		seriesLabels: [],
	},
	currentPipelineToAdGroup: {
		series: [],
		seriesLabels: [],
	},
};

export const totalPipelineRawReport = {
	totalPipelineToChannel: {
		series: [],
		seriesLabels: [],
	},
	totalPipelineToSource: {
		series: [],
		seriesLabels: [],
	},
	totalPipelineToKeyword: {
		series: [],
		seriesLabels: [],
	},
	totalPipelineToCampaign: {
		series: [],
		seriesLabels: [],
	},
	totalPipelineToAdGroup: {
		series: [],
		seriesLabels: [],
	},
};

export const spentRawReport = {
	spentToChannel: {
		series: [],
		seriesLabels: [],
	},
	spentToSource: {
		series: [],
		seriesLabels: [],
	},
	spentToCampaign: {
		series: [],
		seriesLabels: [],
	},
	spentToAdGroup: {
		series: [],
		seriesLabels: [],
	},
	spentToKeyword: {
		series: [],
		seriesLabels: [],
	},
};

export const leadRawReport = {
	leadToChannel: {
		series: [],
		seriesLabels: [],
	},
	leadToSource: {
		series: [],
		seriesLabels: [],
	},
	leadToCampaign: {
		series: [],
		seriesLabels: [],
	},
	leadToAdGroup: {
		series: [],
		seriesLabels: [],
	},
	leadToKeyword: {
		series: [],
		seriesLabels: [],
	},
};

export interface iDimensionFilter {
	name: string;
	value: string;
	localIndex: number;
}

export const showYAxisTitle = (index: any, type?: string): string => {
	if (type) {
		switch (index) {
			case 0:
				return 'revenue';
			case 1:
				return 'oppurtunities';
			case 2:
				return 'spend';
			case 3:
				return 'leads';
			default:
				return '';
		}
	} else
		switch (index) {
			case 0:
				return 'revenue';
			case 1:
				return 'deals';
			case 2:
				return 'pipeline';
			case 3:
				return 'total_pipeline';
			case 4:
				return 'spend';
			case 5:
				return 'leads';
			default:
				return '';
		}
};

export const assignHeight = (res: any) => {
	switch (res.series.length) {
		case 0:
			return 200;
		case 1:
			return 180;
		case 2:
			return 230;
		case 3:
			return 280;
		case 4:
			return 330;
		case 5:
			return 380;
		case 6:
			return 380;
		default:
			return 380;
	}
};
