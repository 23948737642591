import { Box, Grid, Typography, useTheme } from '@mui/material';
import { Suspense, forwardRef, useRef, useState } from 'react';
import ToolTipOnText from '../../components/Tooltip/Tooltip';
import Button from '../../components/Button/Button';
import Share from '@mui/icons-material/Share';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Avatar from '@mui/material/Avatar';
// import ShareReportsIcon from '../../assets/Images/ShareReports';
import ShareReportsIcon from '../../assets/Images/ShareReports';
import './ShareReports.scss';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ShareThroughEmail from './ShareThroughEmail';
import ShareThroughLink from './ShareThroughLink';
import ShareThroughDownload from './ShareThroughDownload';
import { InsightsData } from '../Reports/Dashboard/InsightsData';
import useToast from '../../components/Toast/hooks/useToast';
import useLoader from '../../hooks/useLoader';
import AlertDialog from '../../components/Dialog/Dialog';
import clientLogo from './ClientLogo';
import useApiService from '../../services/api.service';
import ShareReportsEndpoints from './ShareReportsEndpoints';
import useLocalStorage from '../../utils/localStorage';
import { fontSize } from '@mui/system';
import CommonEndpoints from '../../services/commonAPI.Service';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	VARIABLE_INSIGHTS_CHANNEL_PATH_ANALYSIS,
	VARIABLE_INSIGHTS_CHANNEL_TOP_CONVERSION,
} from '../../constants/staticPathVariables';
const ShareReports = forwardRef(
	({
		name,
		id,
		filters,
		exportPDFWithComponent,
		shareReportsRequest,
		ref,
		downloadPDFClassName,
		csvDownloadFunc,
		tabValue,
	}: any) => {
		const theme: any = useTheme();
		const toaster: any = useToast();
		const APIService = useApiService();
		const loader: any = useLoader();
		let dateRange: any = InsightsData.getDefaultRange();
		const [logoImage, setLogoImage]: any = useState();
		const [uploadedLogo, setUploadedLogo]: any = useState();
		const [logoUploaded, setLogoUploaded] = useState(false);
		const [value, setValue] = useState(0);
		const handleChange = (event: React.SyntheticEvent, newValue: number) => {
			setValue(newValue);
		};
		const isTableDataForPdf = useSelector(
			(state: any) => state?.isTableDataForPdf
		);
		const shareReportsRef: any = useRef();
		const addLogoRef: any = useRef();
		const uploadButtonRef: any = useRef();
		const location = useLocation();
		const { pathname, search } = location;
		//check if have logo already
		const checkLogo = useLocalStorage.getItem('logo');
		const getTableExportEmailValue = '';
		const getTableExportNamesArray: any = [];

		//shareReport HTML ---------------------------------------------------------------------------------
		const shareReportsHTML = () => {
			const isObjectEmpty = (objectName: object = {}) => {
				return Object.keys(objectName).length !== 0;
			};

			const checkPathForDisplay = () => {
				if (
					pathname === VARIABLE_INSIGHTS_CHANNEL_PATH_ANALYSIS ||
					pathname === VARIABLE_INSIGHTS_CHANNEL_TOP_CONVERSION
				) {
					return !isObjectEmpty(isTableDataForPdf) ? 'none' : 'block';
				} else {
					return 'block';
				}
			};
			return (
				<Grid xs={12} container>
					<Grid sx={{ minWidth: 600, position: 'relative' }}>
						<div
							className='add-client-logo'
							onClick={() => addLogoRef.current.handleClickOpen()}
							style={{ color: checkLogo ? theme.palette.text.tertiary : '' }}
						>
							Add Client Logo
						</div>
						<Tabs
							value={value}
							onChange={handleChange}
							textColor='primary'
							indicatorColor='primary'
							aria-label='secondary tabs example'
							sx={{ borderBottom: 1, borderColor: 'divider' }}
						>
							<Tab
								style={{ textTransform: 'none', fontSize: '18px' }}
								value={0}
								label='Email'
							/>
							<Tab
								style={{ textTransform: 'none', fontSize: '18px' }}
								value={1}
								label='Link'
							/>
							<Tab
								style={{
									textTransform: 'none',
									fontSize: '18px',
									display: checkPathForDisplay(),
								}}
								value={2}
								label='Download'
							/>
						</Tabs>
						<Grid container padding={'0px 20px'}>
							{value == 0 ? (
								<ShareThroughEmail
									shareReportsRequest={shareReportsRequest}
									id={id}
									dateRange={dateRange}
									filters={filters}
									toaster={toaster}
									loader={loader}
									name={name}
									downloadPDFClassName={downloadPDFClassName}
									exportPDFWithComponent={() => {
										exportPDFWithComponent(true);
									}}
									getTableExportNamesArray={getTableExportNamesArray}
									getTableExportEmailValue={getTableExportEmailValue}
									isTableExport={false}
									closePopup={(data: any) => {
										shareReportsRef.current.handleClose();
									}}
								/>
							) : value == 1 ? (
								<ShareThroughLink
									shareReportsRequest={shareReportsRequest}
									id={id}
									dateRange={dateRange}
									filters={filters}
									downloadPDFClassName={downloadPDFClassName}
									toaster={toaster}
									loader={loader}
									name={name}
									closePopup={(data: any) => {
										shareReportsRef.current.handleClose();
									}}
									exportPDFWithComponent={() => {
										exportPDFWithComponent(true);
									}}
								/>
							) : (
								<ShareThroughDownload
									shareReportsRequest={shareReportsRequest}
									id={id}
									downloadPDFClassName={downloadPDFClassName}
									dateRange={dateRange}
									filters={filters}
									toaster={toaster}
									loader={loader}
									name={name}
									ref={ref}
									closePopup={() => {
										shareReportsRef.current.handleClose();
									}}
									csvDownloadFunc={csvDownloadFunc}
									tabValue={tabValue}
								/>
							)}
						</Grid>
					</Grid>
				</Grid>
			);
		};

		//Submit logo api-----------------------------------------------------------------------------------
		const submitLogo = async (file: any) => {
			const request = {
				reqBody: { base64: file },
				headers: {},
			};
			loader.showLoader();
			return await APIService.post(
				ShareReportsEndpoints.uploadImage(request),
				true
			)
				.then((res: any) => {
					loader.hideLoader();
					toaster.addToast({
						message: 'Logo uploaded successfully',
						timeout: 3000,
						type: 'success',
					});
					getLogo();
					setLogoUploaded(false);
					addLogoRef.current.handleClose();
				})
				.catch((err: any) => {
					loader.hideLoader();
					toaster.addToast({
						message: 'Error while uploading Logo',
						timeout: 5000,
						type: 'error',
					});
				});
		};

		//get logo api--------------------------------------------------------------------------------------
		const getLogo = async () => {
			const reqData = {};
			return await APIService.get(
				ShareReportsEndpoints.getLogoUrl(reqData),
				true
			)
				.then(async (res: any) => {
					useLocalStorage.setItem('logo', res?.data);
				})
				.catch((err: any) => {
					return [];
				});
		};

		//delete logo api--------------------------------------------------------------------------------------
		const deleteLogo = async () => {
			const reqData = {};
			loader.showLoader();
			return await APIService.get(
				ShareReportsEndpoints.deleteLogo(reqData),
				true
			)
				.then(async (res: any) => {
					useLocalStorage.setItem('logo', null);
					setLogoImage(null);
					loader.hideLoader();
					setLogoUploaded(false);
				})
				.catch((err: any) => {
					return [];
				});
		};

		//add logo pop-up HTML---------------------------------------------------------------------------------------
		const addLogoHTML = () => {
			return (
				<div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								width: '370px',
								height: '300px',
								backgroundColor:
									theme.palette.mode === 'dark'
										? 'rgb(36, 40, 47)'
										: 'whiteSmoke',
								borderRadius: '25px',
								display: 'flex', // Use flexbox
								justifyContent: 'center', // Center content horizontally
								alignItems: 'center', // Center content vertically
								overflow: 'hidden', // Hide overflow to maintain rounded corners
								position: 'relative',
								cursor: !logoImage ? 'pointer' : 'auto',
							}}
							onClick={() => (!logoImage && !checkLogo ? uploadLogo() : '')}
						>
							{logoImage ? (
								<img
									src={logoImage}
									className='clientLogo'
									style={{
										maxWidth: '100%',
										maxHeight: '100%',
										padding: '5px',
									}} // Ensure the image fits inside the container
								/>
							) : checkLogo ? (
								<img
									src={checkLogo}
									className='clientLogo'
									style={{
										maxWidth: '100%',
										maxHeight: '100%',
										padding: '15px',
									}}
								/>
							) : (
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										flexDirection: 'column',
									}}
								>
									<CloudUploadIcon
										sx={{
											fontSize: '100px',
										}}
									/>
									<h1>Browse Logo</h1>
								</div>
							)}
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '15rem',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Button
								type='button'
								btnText='Update Logo'
								onClick={uploadLogo}
								className='fullWidthButton'
								disable={!logoImage && !checkLogo}
							/>

							<Button
								type='button'
								btnText='Delete Logo'
								color='error'
								onClick={() => {
									deleteLogo();
									// addLogoRef.current.handleClose();
								}}
								style={{ marginTop: '10px' }}
								className='fullWidthButton'
								disable={!logoImage && !checkLogo}
							/>

							<Button
								type='button'
								btnText='Save'
								onClick={() => submitLogo(uploadedLogo)}
								className='submitButton'
								style={{ width: '8rem' }}
								disable={!logoUploaded}
							/>
						</div>
					</div>
					<Typography variant='h6' mt={2}>
						<li className='logoInstructions'>
							{' '}
							<i>Logo format should be PNG </i>
						</li>
						<li className='logoInstructions'>
							{' '}
							<i>Logo size should be less than 50KB</i>
						</li>
						<li className='logoInstructions'>
							{' '}
							<i>Recommended size for Logo would be 370x140 px</i>
						</li>
					</Typography>
				</div>
			);
		};

		//click on input tag to upload logo which opens popup where we can choose file to upload ------------------
		const uploadLogo = () => {
			uploadButtonRef.current.value = null;
			uploadButtonRef?.current?.click();
		};

		const customTitle = () => (
			<Box
				sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			>
				<Typography
					variant='h4'
					style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}
				>
					Upload Logo
				</Typography>
			</Box>
		);
		const data = {
			fill: theme.palette.text.tertiary,
			width: '20px',
			height: '20px',
		};
		return (
			<>
				<ToolTipOnText title=' Share Reports'>
					<Button
						className='pinIcon bookmarkButtons'
						type='button'
						btnText=''
						variant='outlined'
						style={{
							//  padding: '4px',
							padding: '0px 20px 0px 10px',
							minWidth: '0px',
							// marginRight: '20px',
						}}
						onClick={() => {
							shareReportsRef.current.handleClickOpen();
							setValue(0);
						}}
						startIcon={
							// <Avatar style={{ fontSize: '25px' }} src={shareReportsIcon} />
							<ShareReportsIcon {...data} />
						}
					/>
				</ToolTipOnText>

				<Suspense fallback={<h3>Loading&hellip;</h3>}>
					<AlertDialog
						ref={shareReportsRef}
						maxWidth={'xl'}
						sx={{
							'& .MuiDialog-container': {
								'& .MuiPaper-root': {
									height: '390px',
									width: '100%',
									maxWidth: '660px', // Set your width here
								},
							},
						}}
						title='Share Reports'
						customHTMLContent={shareReportsHTML}
						onConfirm={() => {}}
						onDiscard={() => {
							shareReportsRef.current.handleClose();
						}}
						dialogTitleRootClass='shareReportroot'
						hideCloseButton
					/>
				</Suspense>
				<Suspense fallback={<h3>Loading&hellip;</h3>}>
					<AlertDialog
						ref={addLogoRef}
						sx={{
							'& .MuiDialog-container': {
								'& .MuiPaper-root': {
									height: '510px',
									width: '100%',
									maxWidth: '700px', // Set your width here
								},
							},
						}}
						title={customTitle()}
						customHTMLContent={addLogoHTML}
						onConfirm={() => {}}
						onDiscard={() => {
							setLogoUploaded(false);
							addLogoRef.current.handleClose();
							if (checkLogo) {
								setLogoImage(checkLogo);
							}
							if (!checkLogo) {
								setLogoImage(null);
							}
						}}
						dialogTitleRootClass='addLogoRoot'
						hideCloseButton
					/>
				</Suspense>

				{/*-------------------------- all checks and input for upload logo ----------------------------- */}
				<input
					hidden
					type='file'
					accept='image/*'
					ref={uploadButtonRef}
					onChange={(evt: any) => {
						const uploadedFile = evt.target.files[0];
						if (!uploadedFile) return; // Handle case when no file is selected

						if (uploadedFile.size > 50000) {
							toaster.addToast({
								message: 'Logo size should be less than 50KB',
								timeout: 3000,
								type: 'error',
							});
							setLogoImage('');
							return;
						}

						// const allowedFormats = ['image/png', 'image/jpg', 'image/jpeg'];
						const allowedFormats = ['image/png'];
						if (!allowedFormats.includes(uploadedFile.type)) {
							toaster.addToast({
								message: 'Logo format should be PNG ',
								timeout: 3000,
								type: 'error',
							});
							setLogoImage('');
							return;
						}

						const reader = new FileReader();
						reader.onloadend = function (event) {
							// will return heigth and width of uploaded image -------------
							// var image:any = new Image();
							// //Set the Base64 string return from FileReader as source.
							// //@ts-ignore
							// image.src  = event.target.result;
							// image.onload = function () {
							// 	var height = this.height;
							// 	var width = this.width;
							//   };
							//-----------------------------------------------------------
							const base64String: any = event?.target?.result;
							const splitBase64String = base64String.split(';base64,')[1];
							setUploadedLogo(splitBase64String);
							setLogoImage(URL.createObjectURL(uploadedFile));
							setLogoUploaded(true);
						};
						reader.readAsDataURL(uploadedFile);
					}}
				/>
			</>
		);
	}
);

export default ShareReports;
