export const VARIABLE_ACCOUNT = '/account';
export const VARIABLE_ACCOUNT_WITH_PARAMS = '/account/:id/:token';
export const VARIABLE_INVITED_LOGIN = '/invited/login';
export const VARIABLE_LOGIN = '/login';
export const VARIABLE_EDIT_PROFILE = '/editProfile';
export const VARIABLE_RESET_PASSWORD = '/reset-password';
export const VARIABLE_DEFAULT_ROUTE = '/login';

// Dashboard and Reporting
export const VARIABLE_INSIGHTS_CMO_DASHBOARD = '/insights/cmo/dashboard';
export const VARIABLE_INSIGHTS_CMO_DASHBOARD1 = '/insights/cmo/dashboard1';
export const VARIABLE_CUSTOM_DASHBOARD = '/custom/dashboard';
export const VARIABLE_GA4_REPORTING = '/ga4/reporting';
export const VARIABLE_INSIGHTS_MO_METRICS_HUB = '/insights/mo/metrics/hub';
export const VARIABLE_INSIGHTS_MO_METRICS_HUB_PDF =
	'/insights/mo/metrics/hub/pdf';
export const VARIABLE_INSIGHTS_CHANNEL_PAID_CAMPAIGN_TRACKER_PDF =
	'/insights/channel/paid-campaign-tracker/pdf';

// Custom Reports
export const VARIABLE_CUSTOM_REPORTING = '/custom/reporting/';
export const VARIABLE_CUSTOM_REPORT = '/custom/report';
export const VARIABLE_CUSTOM_REPORT_MODULE = '/custom/report/module';
export const VARIABLE_CUSTOM_REPORT_ADD = '/custom/report/add';

// Insights and Analytics
export const VARIABLE_INSIGHTS_CMO_PERFORMANCE_REPORT =
	'/insights/cmo/performance-report';
export const VARIABLE_INSIGHTS_CMO_CHANNEL_PERFORMANCE =
	'/insights/cmo/channel-performance';
export const VARIABLE_INSIGHTS_CMO_COHORT = '/insights/cmo/cohort';
export const VARIABLE_INSIGHTS_CMO_SALES_CYCLE = '/insights/cmo/sales-cycle';
export const VARIABLE_INSIGHTS_MO_PATH_LENGTH = '/insights/mo/path-length';
export const VARIABLE_INSIGHTS_MO_MODEL_COMPARISON =
	'/insights/mo/model-comparison';
export const VARIABLE_INSIGHTS_CHANNEL_HUBSPOT_REPORT =
	'/insights/channel/hubspot-report';
export const VARIABLE_INSIGHTS_CHANNEL_SEARCH_CONSOLE_REPORT =
	'/insights/channel/search-console-report';
export const VARIABLE_INSIGHTS_CHANNEL_PAID_CAMPAIGN_TRACKER =
	'/insights/channel/paid-campaign-tracker';
export const VARIABLE_INSIGHTS_CHANNEL_PAID_MEDIA_TRACKER =
	'/insights/channel/paid-media-tracker';
export const VARIABLE_INSIGHTS_CHANNEL_NODE_PAID_MEDIA_TRACKER =
	'/insights/channel/node/paid-media-tracker';
export const VARIABLE_INSIGHTS_CHANNEL_PATH_ANALYSIS =
	'/insights/channel/path-analysis';
export const VARIABLE_INSIGHTS_CHANNEL_TOP_CONVERSION =
	'/insights/channel/top-conversion';
export const VARIABLE_INSIGHTS_TOTAL_SPENT = '/insights/total-spent';
export const VARIABLE_INSIGHTS_MARKETING_SPENT = '/insights/marketing-spent';
export const VARIABLE_INSIGHTS_CHANNEL_PERFORMANCE_REPORT =
	'/insights/channel-performance-report';
export const VARIABLE_INSIGHTS_ABM_CONFIGURATION =
	'/insights/abm/configuration';
export const VARIABLE_INSIGHTS_ABM_ACCOUNTS = '/insights/abm/accounts';
export const VARIABLE_INSIGHTS_ABM_ACCOUNT_DASHBOARD =
	'/insights/abm/account-dashboard/:accountId';
export const VARIABLE_INSIGHTS_ABM_COMPARISON_DASHBOARD =
	'/insights/abm/comparison-dashboard';
export const VARIABLE_INSIGHTS_CHANNEL_SMD = '/insights/channel/smd';

// Configuration and Governance
export const VARIABLE_GOVERNANCE_PARAMETER = '/governance/parameter';
export const VARIABLE_IFRAME_TESTING = '/iframe-testing';
export const VARIABLE_GOVERNANCE_CHANNELS_LIST = '/governance/channels/list';
export const VARIABLE_GOVERNANCE_CHANNELS_MANAGE =
	'/governance/channels/manage';
export const VARIABLE_GOVERNANCE_ATTRIBUTES_LIST =
	'/governance/attributes/list';
export const VARIABLE_GOVERNANCE_CAMPAIGNS_LIST = '/governance/campaigns/list';
export const VARIABLE_GOVERNANCE_CAMPAIGNS_TEMPLATES =
	'/governance/campaigns/templates';
export const VARIABLE_GOVERNANCE_CAMPAIGNS_ADD = '/governance/campaigns/add';
export const VARIABLE_GOVERNANCE_CAMPAIGNS_VIEW = '/governance/campaigns/view';
export const VARIABLE_GOVERNANCE_CAMPAIGNS_TEMPLATE_VIEW =
	'/governance/campaigns/template/view';
export const VARIABLE_GOVERNANCE_CAMPAIGNS_EDIT = '/governance/campaigns/edit';
export const VARIABLE_GOVERNANCE_TEMPLATE_EDIT = '/governance/template/edit';
export const VARIABLE_GOVERNANCE_CAMPAIGNS_CLONE =
	'/governance/campaigns/clone';
export const VARIABLE_CONFIGURATION_REPORT = '/configuration/report';

// Settings
export const VARIABLE_SETTINGS_GLOBAL = '/settings/global';
export const VARIABLE_SETTINGS_CONNECTORS = '/settings/connectors';
export const VARIABLE_SETTINGS_CONNECTORS_WITH_PARAMS =
	'/settings/connectors/:type/:configure';
export const VARIABLE_SETTINGS_USER_ACCESS_MANAGEMENT =
	'/settings/user-access-management';
export const VARIABLE_SETTINGS_CONNECTORS_ADROLL_CONFIGURE =
	'/settings/connectors/adroll/configure';
export const VARIABLE_SETTINGS_CONNECTORS_CRM_CONFIGURE =
	'/settings/connectors/crm/configure';
export const VARIABLE_SETTINGS_CONNECTORS_CRM_HUBSPOT_CONFIGURE =
	'/settings/connectors/crm/hubspot/configure';
export const VARIABLE_SETTINGS_CONNECTORS_GOOGLE_CONSOLE_CONFIGURE =
	'/settings/connectors/googleconsole/configure';
export const VARIABLE_SETTINGS_CONNECTORS_BING_ADS_CONFIGURE =
	'/settings/connectors/bing-ads/configure';
export const VARIABLE_SETTINGS_CONNECTORS_GOOGLE_ADS_CONFIGURE =
	'/settings/connectors/google-ads/configure';
export const VARIABLE_SETTINGS_CONNECTORS_GOOGLE_CONFIGURE =
	'/settings/connectors/google/configure';
export const VARIABLE_SETTINGS_CONNECTORS_GOOGLE_ANALYTICS_CONFIGURE =
	'/settings/connectors/googleanalytics/configure';
export const VARIABLE_SETTINGS_CONNECTORS_GOOGLE_ANALYTICS_CONFIGURE_REPORTING =
	'/settings/connectors/googleanalytics/configure/reporting';
export const VARIABLE_SETTINGS_CONNECTORS_MA_MARKETO_CONFIGURE =
	'/settings/connectors/ma/marketo/configure';
export const VARIABLE_SETTINGS_CONNECTORS_HUBSPOT_MA_CONFIGURE =
	'/settings/connectors/hubspot-ma/configure';
export const VARIABLE_SETTINGS_USER_ACCESS_MANAGEMENT_LIST =
	'/settings/user-access-management/list';
export const VARIABLE_SETTINGS_CHANNEL_MANAGER = '/settings/channel-manager';
export const VARIABLE_SETTINGS_SEGMENT_MANAGER = '/settings/segment-manager';
export const VARIABLE_SETTINGS_CONTENT_GROUPING = '/settings/content-grouping';
export const VARIABLE_SETTINGS_CAMPAIGN_MAPPING = '/settings/campaign-mapping';

// Miscellaneous
export const VARIABLE_DATA_QUALITY_SF_SCORING = '/data-quality/sf-scoring';
export const VARIABLE_DATA_QUALITY_HUBSPOT_SCORING =
	'/data-quality/hubspot-scoring';
export const VARIABLE_COMING_SOON = '/coming-soon';
