export const LOGIN_RESPONSE = 'LOGIN_RESPONSE';
export const LOGOUT = 'LOGOUT';
export const LOADER = 'LOADER';
export const SIDEBAR = 'SIDEBAR';
export const EDITPROFILE = 'EDITPROFILE';
export const SEGMENT_LIST_UPDATE = 'SEGMENT_LIST_UPDATE';
export const INSIGHT_FILTER_CHANGE = 'INSIGHT_FILTER_CHANGE';
export const TOGGLESIDEBAR = 'TOGGLESIDEBAR';
export const CONNECTOR_CHANGE = 'CONNECTOR_CHANGE';
export const TOGGLETHEME = 'TOGGLETHEME';
export const INSIGHTS_DRAWER = 'INSIGHTSDRAWER';
export const ANSWER_DRAWER = 'ANSWERDRAWER';
export const INSIGHTS_QUESTIONS = 'INSIGHTSQUESTIONS';
export const ACCESS_AND_REFRESH_TOKEN_CHANGE =
	'ACCESS_AND_REFRESH_TOKEN_CHANGE';
