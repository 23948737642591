const useLocalStorage = {
  getItem: (key: string) => {
    const data: any = localStorage.getItem(key);
    try {
      return JSON.parse(data);
    } catch (e) {
      return data;
    }
    // return data ? JSON.parse(data) : null;
  },
  setItem: (key: string, value: any) =>
    localStorage.setItem(key, JSON.stringify(value)),
  removeItem: (key: string) => {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      return false;
    }
  },
  clear: () => localStorage.clear(),
  key: (index: number) => localStorage.key(index),
};

export default useLocalStorage;
