export interface IDatePickerProps {
	showCustomComponent?: any;
	onChange?: any;
	widthCheckOnFormat?: boolean;
	start_date?: any;
	end_date?: any;
	pre_start_date?: any;
	pre_end_date?: any;
	defaultMonths?: any;
	minLastYear?: number;
	onCompareChange?: any;
	showComparison?: any;
	disableDPCheck?: any;
	dontSaveInLS?: boolean;
	selectNext7DaysByDefault?: boolean;
	isMultipleRanges?: boolean;
	showPercentageChange?: boolean;
	preview?: any;
	isShowQuarter?: any;
	startDatePlaceholder?: string;
	endDatePlaceholder?: string;
	showQuatreOptions?: boolean;
}

export interface IDateRange {
	startDate: any;
	endDate: any;
	key: any;
}

export const IRangePickerFormats = {
	med_screen: 'MMMM DD, YYYY',
	tab: 'MMM DD, YYYY',
	mobile: 'MMM DD, YY',
	range_med: 'MMM DD,YYYY',
	range_responsive: 'MMM DD, YYYY',
};
