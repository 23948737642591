import moment from 'moment';

export const defaultDateFormat = 'YYYY-MM-DD';

export const DateTimeFormatter = {
	getPastValueFromToday(months: any, format = defaultDateFormat) {
		return moment().subtract(months, 'M').format(format);
	},
	getCurrentDate(format = defaultDateFormat) {
		return moment().format(format);
	},
	getNDaysBeforeDateFromToday(n: number, format = defaultDateFormat) {
		return moment().subtract(n, 'd').format(format);
	},
	getTimeDifference(time1: any, time2: any) {
		// Time should be in tz for this,
		let difference = Math.abs(
			moment.duration(moment(time1).diff(moment(time2))).asHours()
		);

		if (difference >= 1 && difference < 23) {
			return `${+difference.toFixed(0)}h`;
		} else if (difference < 1) {
			if (difference < 0.0166667) {
				difference = Math.abs(
					moment.duration(moment(time1).diff(moment(time2))).asSeconds()
				);
				return `${+difference.toFixed(0)}s`;
			} else
				difference = Math.abs(
					moment.duration(moment(time1).diff(moment(time2))).asMinutes()
				);
			return `${+difference.toFixed(0)}m`;
		} else {
			difference = Math.abs(
				moment.duration(moment(time1).diff(moment(time2))).asDays()
			);
			return `${+difference.toFixed(0)}d`;
		}
	},
	getDateInFormat(date: any, format = defaultDateFormat) {
		return moment(date).format(format);
	},
	checkIfDateBefore(date: Date, compareDate: Date) {
		return (
			new Date(date).getFullYear() < new Date(compareDate).getFullYear() &&
			new Date(date).getMonth() < new Date(compareDate).getMonth() &&
			new Date(date).getDate() < new Date(compareDate).getDate()
		);
		// return new Date(date.toDateString()) < new Date(compareDate.toDateString());
	},
	// get difference of days b/w start date and end date that you pass
	getDaysDifference(startDate: any, endDate: any, format = defaultDateFormat) {
		let admission = moment(startDate, format);
		let discharge = moment(endDate, format);
		let daysDiff = discharge.diff(admission, 'days');
		return daysDiff;
	},
};
