const appEndpoints = {
	getLoginDetails(data: any) {
		return {
			url: 'governance/oauth',
			headers: data.headers,
			data: {},
		};
	},
	keyCloakAuth(data: any) {
		return {
			url: 'kcauth/verify',
			data: data.headers,
		};
	},
	getLogos(data?: any) {
		return {
			url: 'sharedfeature/get_client_logo_url',
			headers: data.headers,
			data: {},
		};
	},
	getColorTheme(data?: any) {
		return {
			url: 'marketo/get_color_settings',
			headers: data.headers,
			data: {},
		};
	},
	getShareReportLogos(data?: any) {
		return {
			url: 'sharedfeature/get_client_logo',
			headers: data.headers,
			data: {},
		};
	},
	regenrateKeyCloakToken(data: any) {
		return {
			url: 'kcauth/refreshToken',
			headers: data,
			data: {},
		};
	},
	validateAccessToken(data: any) {
		return {
			url: 'kcauth/validate',
			headers: data.headers,

			data: {},
		};
	},
	segment_filter_name(data: any) {
		return {
			url: 'segment/segment_filter_name',
			/*headers: data,
			data: {},*/
			data: data.reqBody,
			headers: data.headers.request,
		};
	},
};
export default appEndpoints;
