import { Alert, Snackbar } from '@mui/material';
import Stack from '@mui/material/Stack';
import React, { Fragment, useRef } from 'react';

import { IToast } from './interfaces';
import { useToastContext } from './Toast.context';
import classes from './Toast.module.scss';

const Toast: React.FC = () => {
	const [toasts]: any = useToastContext();

	const toastPos: any = useRef();

	function GetAlertBasedOnType(type: any, message: any) {
		return <Alert severity={type}>{message}</Alert>;
	}

	return (
		<div className={classes['toast-container']} style={toastPos.current}>
			<Stack sx={{ width: '100%' }} spacing={8}>
				{toasts.map((item: IToast, index: number) => {
					toastPos.current = item.pos;
					return (
						<Fragment key={Math.random()}>
							<Snackbar
								anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
								open
								key={'top' + 'center'}
							>
								{index === 0 ? (
									GetAlertBasedOnType(item.type, item.message)
								) : (
									<div />
								)}
							</Snackbar>
						</Fragment>
					);
				})}
			</Stack>
		</div>
	);
};
export default React.memo(Toast);
