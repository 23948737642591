const DynamicTableEndPoint = {
	getCampaignTableData(data: any) {
		return {
			url: 'paidcampaigntracker/fetchCampaignPerformance',
			data: data.request,
			headers: data.headers,
		};
	},
	getCampaignList(data: any) {
		return {
			url: 'paidcampaigntracker/campaignSearch',
			data: data.request,
			headers: data.headers,
		};
	},
	postPdfColumns(data: any) {
		return {
			url: 'paidcampaigntracker/updatePdfFilters',
			data: data.request,
			headers: data.headers,
		};
	},
	getPdfColumns(data: any) {
		return {
			url: `paidcampaigntracker/getPdfFilters`,
			data: data.request,
			headers: data.headers,
		};
	},
	getCSV(data: any) {
		return {
			url: 'sharedfeature/export_file',
			data: data,
			headers: data.headers,
		};
	},
};

export default DynamicTableEndPoint;
