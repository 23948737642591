const EditProfileEndPoints = {
	getProfileData(data: object) {
		return {
			url: 'governance/profile_get',
			data: data,
		};
	},
	logoutUser(data: object) {
		return {
			url: 'kcauth/logout',
			headers: {},
			data: data,
		};
	},
	deleteAccount(data: object) {
		return {
			url: 'governance/delete_account',
			headers: {},
			data: data,
		};
	},
	changePassword(data: object) {
		return {
			url: 'governance/change_password',
			data,
		};
	},
	sendProfileData(data: any) {
		return {
			url: 'governance/update_profile',
			data: data.reqBody,
			headers: data.headers,
		};
	},
	toggleDarkTheme(data: object) {
		return {
			url: 'connectorcore/toggle_theme',
			data,
		};
	},
};

export default EditProfileEndPoints;
