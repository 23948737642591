import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk'; // Import the redux-thunk middleware
import { cmoDashboardReducer } from './reducers/cmoDashboardReducer';

// Apply the middleware when creating the store
const cmoDasboardStore = createStore(
	cmoDashboardReducer,
	applyMiddleware(thunk)
);

export default cmoDasboardStore;
