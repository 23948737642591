function twoDecimal(n: any): any {
  if (n === -100) {
  }
  if (n) {
    const round = Math.round((n + Number.EPSILON) * 100) / 100;
    return round;
  }
  return 0;
}

export default twoDecimal;
