import { sideBarSectionData } from '../../containers/Settings/CustomNavigationBar/CustomNavigationData';

function getListUniqueId(list) {
	let tempUniqueIdArray = [];
	for (const element of list) {
		tempUniqueIdArray.push(element.unique_id);
	}
	return tempUniqueIdArray;
}

function getIndexByUniqueId(list, unique_id) {
	return list.findIndex((item) => item.unique_id == unique_id);
}

/**
 * @author : Chetan Dhiman
 * @description : this is function which is used for updating the sidebar list
 * @handled_scenarios : 1. Report Name Can Be Change
 * 2. Childrens can change
 * 3. Deletion of Object
 * 4. Addition of Object
 * 5. Maintain Sequence of Reports
 * @variables : 1. backendList = this is list which is saved on backend when user save its custom routes
 *              so this functions helps to update the backend list so that new or deletion of object
 *              can be update
 *              2. frontendList = this list is which is stored at frontend which is saved in customnavigationdata.ts
 */

export function getUpdatedSideBarList(backendList, frontEndList) {
	let frontendList = [...frontEndList];
	let tempBackendList = [...backendList];
	// get unique id's of both frontend and backend list

	let backendListUniqueId = getListUniqueId(tempBackendList);
	let frontendListUniqueId = getListUniqueId(frontendList);

	/**
	 * @case : Case 1 : Addition of any new section on sidebar
	 */

	for (const element of frontendList) {
		let foundedIndex = getIndexByUniqueId(tempBackendList, element.unique_id);
		if (!backendListUniqueId.includes(element.unique_id)) {
			tempBackendList.push(element);
		} else {
			if (foundedIndex != -1) {
				tempBackendList[foundedIndex].value = element.value;
				tempBackendList[foundedIndex].name = element.name;
			}
		}
	}

	/**
	 * @case : Case 2 : Remove object which is only in backend list
	 */

	// this varibale is for having modify backend list filtered out list which is not in
	// frontend list now
	let tempBackendListArray = [];

	for (const element of tempBackendList) {
		if (frontendListUniqueId.includes(element.unique_id)) {
			tempBackendListArray.push(element);
		}
	}

	tempBackendList = tempBackendListArray;

	/**
	 * @case : Case 3 : Looking out children modification
	 */

	for (let index = 0; index < tempBackendList.length; index++) {
		let foundedIndex = getIndexByUniqueId(
			frontendList,
			tempBackendList[index].unique_id
		);
		if (foundedIndex != -1) {
			let frontEndListItem = frontendList[index];
			tempBackendList[index].name = frontEndListItem.name;
			tempBackendList[index].children = modifyChildren(
				frontEndListItem.children,
				tempBackendList[index].children
			);
		}
	}
	return tempBackendList;
}

/**
 * @author Chetan Dhiman
 * @description This function helps us to update the children of parent list
 *              function accepts two paramter forntEnd children and backend
 *              children on the basis of fornt end children backedn will update
 */

function modifyChildren(frontEndChildren, backEndChildren) {
	let backEndChildrenUniqueId = getListUniqueId(backEndChildren);
	let frontEndChildrenUniqueId = getListUniqueId(frontEndChildren);

	/**
	 * @case : Case 1 : Addition of any new children in list
	 */

	for (const element of frontEndChildren) {
		if (!backEndChildrenUniqueId.includes(element.unique_id)) {
			backEndChildren.push(element);
		}
	}
	/**
	 * @case : Case 2 : Remove object which is only in backend list
	 */

	let tempBackendListChildrenArray = [];

	for (const element of backEndChildren) {
		if (frontEndChildrenUniqueId.includes(element.unique_id)) {
			tempBackendListChildrenArray.push(element);
		}
	}
	backEndChildren = tempBackendListChildrenArray;

	for (const element of backEndChildren) {
		let backEndChildrenItem = element;
		let foundedIndex = getIndexByUniqueId(
			frontEndChildren,
			backEndChildrenItem.unique_id
		);
		if (foundedIndex != -1) {
			backEndChildrenItem.name = frontEndChildren[foundedIndex].name;
			if (backEndChildrenItem?.children?.length) {
				backEndChildrenItem.children = modifyChildren(
					frontEndChildren[foundedIndex].children,
					backEndChildrenItem.children
				);
			}
		}
	}

	return backEndChildren;
}
