import React, { createContext, useContext, useState, useEffect } from 'react';

const ThemeContext = createContext();

export const ThemeApp = ({ children }) => {
	const defaultPrimaryColor = '#9149ff';
	const defaultSecondaryColor = '#D412FF';
	const defaultTableColor = '';
	const defaultGraphColors = [
		'#3DC6C3',
		'#50E3C2',
		'#E3B9B7',
		'#b899b6',
		'#57167E',
		'#6a539d',
		'#75ab68',
		'#c4855f',
		'#9fa639fc',
		'#923b66fc',
	];
	const [barGraphColors, setBarGraphColors] = useState([]);
	const [trendGraphColors, setTrendGraphColors] = useState([]);
	const [areaChartColors, setAreaChartColors] = useState([]);
	const [donutPieChartColors, setDonutPieChartColors] = useState([]);
	const [funnelGraphColors, setFunnelGraphColors] = useState([]);
	const [tableColors, setTableColors] = useState('');
	const [primaryColorApp, setPrimaryColorApp] = useState(
		localStorage.getItem('primaryColor') || defaultPrimaryColor
	);
	const [secondaryColorApp, setSecondaryColorApp] = useState(
		localStorage.getItem('secondaryColor') || defaultSecondaryColor
	);
	const [tableColor, setTableColor] = useState(
		localStorage.getItem('tableColor') || defaultTableColor
	);
	const [graphColorsApp, setGraphColorsApp] = useState(
		JSON.parse(localStorage.getItem('graphColors')) || defaultGraphColors
	);

	useEffect(() => {
		localStorage.setItem('primaryColor', primaryColorApp);
	}, [primaryColorApp]);

	useEffect(() => {
		localStorage.setItem('secondaryColor', secondaryColorApp);
	}, [secondaryColorApp]);

	useEffect(() => {
		localStorage.setItem('tableColor', tableColor);
	}, [tableColor]);

	useEffect(() => {
		localStorage.setItem('graphColors', JSON.stringify(graphColorsApp));
	}, [graphColorsApp]);

	const handlePrimaryColorChange = (color) => {
		setPrimaryColorApp(color);
	};

	const handleSecondaryColorChange = (color) => {
		setSecondaryColorApp(color);
	};

	const handleGraphColorChange = (colors) => {
		setGraphColorsApp(colors);
	};

	const handleTableColorChange = (color) => {
		setTableColor(color);
	};

	const handleDefaultTheme = () => {
		setPrimaryColorApp(defaultPrimaryColor);
		setSecondaryColorApp(defaultSecondaryColor);
		setTableColor(defaultTableColor);
		setGraphColorsApp(defaultGraphColors);
		window.location.reload();
	};
	console.log('addding stateee', barGraphColors, trendGraphColors);
	return (
		<ThemeContext.Provider
			value={{
				primaryColorApp,
				handlePrimaryColorChange,
				secondaryColorApp,
				handleSecondaryColorChange,
				graphColorsApp,
				handleGraphColorChange,
				tableColor,
				handleTableColorChange,
				handleDefaultTheme,

				barGraphColors,
				trendGraphColors,
				areaChartColors,
				donutPieChartColors,
				funnelGraphColors,

				setBarGraphColors,
				setTrendGraphColors,
				setAreaChartColors,
				setDonutPieChartColors,
				setFunnelGraphColors,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};

export const useThemeContext = () => useContext(ThemeContext);
