function insightAnswers(n: any, response: any): any {
	let answer = '';
	switch (n) {
		case 1:
			answer = `On an average <span class='highlight'>${
				response
					? response?.total_leads_require_to_close_oppr !== null
						? response?.total_leads_require_to_close_oppr
						: '-'
					: '-'
			}</span> leads are required to close an opportunity. Out of the total <span class='highlight'>${
				response
					? response?.total_leads !== null
						? response?.total_leads
						: '-'
					: '-'
			}</span> leads, <span class='highlight'>${
				response
					? response?.total_closed_won !== null
						? response?.total_closed_won
						: '-'
					: '-'
			}</span> got closed in the last 6 months.`;
			break;
		case 2:
			answer = `On an average <span class='highlight'>${
				response
					? response?.lead_win_rate !== null
						? response?.lead_win_rate
						: '-'
					: '-'
			}</span> leads (that entered sales funnel) are
    required to close an opportunity. Out of the total <span class='highlight'>${
			response
				? response?.total_leads !== null
					? response?.total_leads
					: '-'
				: '-'
		}</span> leads,
    <span class='highlight'>${
			response
				? response?.total_closed_won !== null
					? response?.total_closed_won
					: '-'
				: '-'
		}</span> got closed in the last 6 months.`;
			break;
		case 3:
			answer = `Maximum delay of <span class='highlight'>${
				response
					? response?.maximum_delayed_avg_days !== null
						? response?.maximum_delayed_avg_days
						: '-'
					: '-'
			}</span> days on an average is observed at the <span class='highlight'>${
				response
					? response?.maximum_delayed_stage !== null
						? response?.maximum_delayed_stage
						: '-'
					: '-'
			}</span> stage of the funnel.`;
			break;
		case 4:
			answer = `Maixmum delay of <span class='highlight'>${
				response
					? response?.maximum_delayed_avg_days !== null
						? response?.maximum_delayed_avg_days
						: '-'
					: '-'
			}</span> days on an average is observed at the <span class='highlight'>${
				response
					? response?.maximum_delayed_stage !== null
						? response?.maximum_delayed_stage
						: '-'
					: '-'
			}</span> stage in opportunity stage of the funnel.`;
			break;
		case 5:
			answer = `In the past 6 months, the top <span class='highlight'>${
				response
					? response?.top_channels !== null
						? response?.top_channels
						: '-'
					: '-'
			}</span>  channels driving revenue are - <span class='highlight'>${
				response
					? response.final_graph_data.months.length
						? response.final_graph_data.months.join(', ')
						: ''
					: ''
			}</span>.`;
			break;
		case 6:
			answer = `In the past 6 months, the top <span class='highlight'>${
				response
					? response?.top_channels !== null
						? response?.top_channels
						: '-'
					: '-'
			}</span>  channels driving pipeline are - <span class='highlight'>${
				response
					? response.final_graph_data.months.length
						? response.final_graph_data.months.join(', ')
						: ''
					: ''
			}</span>.`;
			break;
		case 7:
			answer = `On an average total <span class='highlight'>${
				response
					? response?.avg_sales_follow_ups !== null
						? response?.avg_sales_follow_ups
						: '-'
					: '-'
			}</span> sales follow ups are leading to a closed won deal. In the past 6 months, <span class='highlight'>${
				response
					? response?.total_sales_touches !== null
						? response?.total_sales_touches
						: '-'
					: '-'
			}</span> sales touches were needed to close <span class='highlight'>${
				response
					? response?.total_closed_won !== null
						? response?.total_closed_won
						: '-'
					: '-'
			}</span> deals.`;
			break;
		case 8:
			answer = `On an average <span class='highlight'>${
				response
					? response?.avg_sales_follow_ups !== null
						? response?.avg_sales_follow_ups
						: '-'
					: '-'
			}</span> marketing sales follow ups are leading to a closed won deal. In the past 6 months, <span class='highlight'>${
				response
					? response?.total_sales_touches !== null
						? response?.total_sales_touches
						: '-'
					: '-'
			}</span> marketing sales touches were needed to close <span class='highlight'>${
				response
					? response?.total_closed_won !== null
						? response?.total_closed_won
						: '-'
					: '-'
			}</span> deals.`;
			break;
		case 9:
			answer = `On an average <span class='highlight'>${
				response
					? response?.avg_sales_follow_ups !== null
						? response?.avg_sales_follow_ups
						: '-'
					: '-'
			}</span> sales follow ups are leading to a closed won deal. In the past 6 months, <span class='highlight'>${
				response
					? response?.total_sales_touches !== null
						? response?.total_sales_touches
						: '-'
					: '-'
			}</span> sales touches were needed to close <span class='highlight'>${
				response
					? response?.total_closed_won !== null
						? response?.total_closed_won
						: '-'
					: '-'
			}</span> deals.`;
			break;
		case 10:
			answer = `Please refer to the graph for the quarterly targets and the current status.`;
			break;
		case 11:
			answer = `Please refer to the graph for the quarterly targets and the current status.`;
			break;
		case 12:
			answer = `Please refer to the graph for the quarterly targets and the current status.`;
			break;
		case 13:
			answer = `In the past 6 months, there are <span class='highlight'>${
				response
					? response?.total_untouched_opprs !== null
						? response?.total_untouched_opprs
						: '-'
					: '-'
			}</span> open opportunities left untouched for more than 3 months.`;
			break;
		case 14:
			answer = `Here are the top URL's in terms of leads, opportunities and closed won deal.`;
			break;
		case 15:
			answer = `Here is the percentage of repeat wrt pipeline type.`;
			break;
		default:
			answer = '';
	}
	return answer;
}

export default insightAnswers;
